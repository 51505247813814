import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { ParallaxProvider } from 'react-scroll-parallax';
import MarqueeProjects from "../components/MarqueeProjects"

const WorkIndex = ({ data, location }) => {
  // Check if data is defined and has the necessary properties
  const siteTitle = data?.site?.siteMetadata?.title || `Title`
  const projects = data?.allMarkdownRemark?.nodes?.filter(node => node.frontmatter.imgSrc || node.frontmatter.heroVideo) || []

  if (projects.length === 0) {
    return (
      <Layout location={location} title={siteTitle} isIndex={true}>
        <p>
          There are currently no projects available. Please check back later for updates.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle} isIndex={true}>
      <ParallaxProvider>
        <MarqueeProjects projects={projects} />
        <div className="flex justify-center mb-5">
          <Link to="/work" className="inline-block p-4 text-black w-full CyGroteskGrandBold text-center border-black hover:bg-black hover:text-white">
            See All Work
          </Link>
        </div>
      </ParallaxProvider>
    </Layout>
  )
}

export default WorkIndex

export const Head = () => <Seo title="Director of Engineering Portfolio" />


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fileAbsolutePath: { regex: "/content/projects/" } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          imgSrc
          heroVideo
        }
      }
    }
  }
`
